import gql from "graphql-tag";

export const CREATE_SONITY_PROFILE = gql`
  mutation createSonityProfile(
    $email: String!
    $password: String!
    $sonity_account_id: ID!
    $first_name: String
    $last_name: String
    $img: String
    $mime: String
  ) {
    create_sonity_profile(
      email: $email
      password: $password
      sonity_account_id: $sonity_account_id
      first_name: $first_name
      last_name: $last_name
      img: $img
      mime: $mime
    ) {
      id
      email
      status
      selected_proxy_id
      preferences {
        id
        type
        time_zone
        work_on_weekends
        start_time
        end_time
        extract_mode
        thread_sync_mode
        revoke_mode
        pause_time
        scan_time
        auto_launch
        preferences
        extensions
      }
      contact {
        id
        first_name
        last_name
      }

      created_at
      updated_at
    }
  }
`;

export const REMOVE_SONITY_PROFILE = gql`
  mutation removeSonityProfile($id: ID! $sonity_account_id: ID) {
    remove_sonity_profile(id: $id, sonity_account_id: $sonity_account_id) {
      id
      email
      status
      preferences {
        id
        type
        time_zone
        work_on_weekends
        start_time
        end_time
      }
      created_at
      updated_at
    }
  }
`;

export const UPDATE_SONITY_PROFILE = gql`
  mutation updateSonityProfile(
    $id: ID!
    $email: String
    $password: String
    $status: String
    $is_salesnav: Boolean
  ) {
    update_sonity_profile(
      id: $id
      email: $email
      password: $password
      status: $status
      is_salesnav: $is_salesnav
    ) {
      id
      email
      password
      status
      is_salesnav
      created_at
      updated_at
      selected_proxy_id
      preferences {
        id
        type
        time_zone
        work_on_weekends
        start_time
        end_time
        extract_mode
        thread_sync_mode
        revoke_mode
        pause_time
        scan_time
        preferences
        extensions
      }
      selected_proxy {
        id
        name
        host
        
      }
      preferences {
        id
        type
        time_zone
        work_on_weekends
        start_time
        end_time
        extract_mode
        thread_sync_mode
        revoke_mode
        pause_time
        scan_time
        auto_launch
        preferences
        extensions
      }
    }
  }
`;

export const SET_SELECTED_PROXY = gql`
  mutation setSelectedProxy($sonity_profile_id: ID!, $selected_proxy_id: ID) {
    set_selected_proxy(
      sonity_profile_id: $sonity_profile_id
      selected_proxy_id: $selected_proxy_id
    ) {
      id
      selected_proxy_id
      selected_proxy {
        id
        name
        host

      }
    }
  }
`;
