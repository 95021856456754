import React, { useCallback, useEffect, useState } from "react";

import { feathersClient } from "../attach-client";

export default (user, updateMessage, state, mainLayoutStateFunctions) => {
  const [mounted, setMounted] = useState(false);

  const {
    setSelectedSonityAccount,
    setSonityAccounts,
    setUser
  } = mainLayoutStateFunctions;

  useEffect(() => {
    //IF WE DON'T HAVE A USER ON THE MAINLAYOUTCTX, WE WANT TO SET ONE
    // if (!user) {
    //   const currentUser = feathersClient.get("user");
    //   console.debug("Current User on useSonityAccounts", currentUser);
    //   setUser(currentUser);
    // }

    //v1
    if (feathersClient && user && user.sonity_account_id) {
      feathersClient
        .service("sonity-accounts")
        .get(user.sonity_account_id)
        .then(sa => {
          setSelectedSonityAccount(sa);
          setSonityAccounts([sa]);
          feathersClient.set("selenium_endpoint", sa.selenium_endpoint);
        })
        .catch(console.error);
      setMounted(true);
    }
    //possibly v2
    if (feathersClient && user && !user.sonity_account_id && !mounted) {
      console.log("v2", {
        user,
        svc: feathersClient
          .service("sonity-accounts")
      })
      feathersClient
        .service("sonity-accounts")
        .find({
          query: {
            user_id: user.id
          }
        })
        .then(sa => {
          //setSelectedSonityAccount(sa);
          console.log("v2 got sa:", sa)
          if (sa.data && sa.data.length > 0) {
            setSonityAccounts([...sa.data]);

            //TODO: we may wish to create a SonityAccount selector dialog here
            setSelectedSonityAccount(sa.data[0]);
          }
        })
        .catch((err) => {
          console.log("v2 sonityAccounts fetch err", feathersClient)
          console.error(err)
        });
      setMounted(true);
    }
  }, [user, feathersClient]);



  return {};
};
