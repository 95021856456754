import { useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";

import { GET_SONITY_PROFILES } from "../gql/queries/SonityProfiles";
import {
  CREATE_SONITY_PROFILE,
  REMOVE_SONITY_PROFILE,
  UPDATE_SONITY_PROFILE
} from "../gql/mutations/SonityProfiles";

import {
  CREATE_PROXY,
  DELETE_PROXY,
  UPDATE_PROXY
} from "../gql/mutations/Proxies";

import { SET_SELECTED_PROXY } from "../gql/mutations/SonityProfiles";


/**
 * @name useSonityProfiles
 * @type component
 * @desc Is a hook that allows you tro perform queries and changes on a user profiles
 * @prop {*} user - Current user
 * @prop {*} updateMessage  - updateMessage function for showing snackbar
 * @prop {*} extraArgs - extra arguments 
 * @prop {*} mainLayoutState - MainLayout current state
 * @prop {*} mainLayoutStateFunctions  - Main Layout state functions
 * @returns An object with sonity profiles date and functions
 */
const useSonityProfiles = (
  mainLayoutState,
  mainLayoutStateFunctions,
  updateMessage
) => {
  const { selectedSonityProfile, sonityProfiles, eventSubs } = mainLayoutState;


  let mounted = mainLayoutState ? mainLayoutState.mounted:false
  const {setSonityProfileToDelete, setSonityProfiles, setSelectedSonityProfile, setActiveProfile, setMainLayoutState } = mainLayoutStateFunctions


  const [getSonityProfiles, sonityProfilesResult] = useLazyQuery(
    GET_SONITY_PROFILES,
    {
      onCompleted: data => {
        if (data && data.sonity_profiles) {
          let sonityProfiles = data.sonity_profiles.profiles
          let statuses = data.sonity_profiles.statuses


          setMainLayoutState({
            sonityProfiles,
            statuses
          })
        }
      },
      // variables: {
      //   sonity_account_id: selectedSonityAccount.id
      // },
      onError: error => {
        console.error(error);

        updateMessage({
          isShown: true,
          variant: "error",
          content: "Cannot fetch profiles"
        });
      }
    }
  );

  const [removeSonityProfile /* removedSonityProfile */] = useMutation(
    REMOVE_SONITY_PROFILE,
    {
      //refetchQueries: ["getSonityProfiles"],
      onCompleted: data => {
        //setConfirmDialogOpened(false);
        setSonityProfileToDelete(null);
        if (data && data.remove_sonity_profile) {
          mainLayoutStateFunctions.removeSonityProfile(
            data.remove_sonity_profile.id
          );
        }
        if (mounted)
          updateMessage({
            isShown: true,
            variant: "success",
            content: "Sccessfully removed profile"
          });
      }
    }
  );
  const [createSonityProfile /*createdSonityProfile */] = useMutation(
    CREATE_SONITY_PROFILE,
    {
      //refetchQueries: ["getSonityProfiles"]
      //tagging with default preferences bc graphql not returning them for some reason
      onCompleted: data => {
        if (data && data.create_sonity_profile) {
          setSonityProfiles([
            ...mainLayoutState.sonityProfiles,
            {
              ...data.create_sonity_profile,
              preferences: {
                sonity_account_id: data.create_sonity_profile.sonity_account_id,
                sonity_profile_id: data.create_sonity_profile.id,
                time_zone: "America/New_York",
                work_on_weekends: false,
                start_time: "07:00",
                end_time: "16:00"
              }
            }
          ]);
        }
      }
    }
  );

  const [updateSonityProfile, updatedSonityProfile] = useMutation(
    UPDATE_SONITY_PROFILE,
    {
      //refetchQueries: ["getSonityProfiles"],
      onCompleted: data => {
        if (data.update_sonity_profile) {
          mainLayoutStateFunctions.updateSonityProfile(
            data.update_sonity_profile.id,
            data.update_sonity_profile
          );
        }
        updateMessage({
          isShown: true,
          variant: "success",
          content: "Profile was successfully updated."
        });
        if (mounted) setActiveProfile(data.update_sonity_profile);
      },
      onError: err => {
        console.error("err", err);
      }
    }
  );


    /* Proxies */

    const [createProxy, createProxyResult] = useMutation(CREATE_PROXY, {
      onCompleted: data => {
        const { create_proxy } = data;
        const { name, host } = create_proxy;
        updateMessage({
          isShown: true,
          variant: "success",
          content: `Proxy ${name} (${host}) has been created.`
        });

        let copy = [...sonityProfiles];
        copy = copy.map(sp=>({
            ...sp,
            proxies: [...sp.proxies, create_proxy]
        }));
        setSonityProfiles(copy);
        console.log("[useSonityProfiles]ProxyCreated:", create_proxy)
      },
      onError: err => console.error(err)
    });

    const [updateProxy, updateProxyResult] = useMutation(UPDATE_PROXY, {
      onCompleted: data => {
        const { update_proxy } = data;
        const { name, host } = update_proxy;
        updateMessage({
          isShown: true,
          variant: "success",
          content: `Proxy ${name} (${host}) has been updated.`
        });

        let copy = [...sonityProfiles];

        const updatedProxies = (sonity_profile) => sonity_profile
                                                      .proxies
                                                      .map(proxy => proxy.id === update_proxy.id ? update_proxy: proxy)


        copy = copy.map(sp=>({
            ...sp,
            proxies: updatedProxies(sp)
        }));

        setSonityProfiles(copy);
        console.log("[useSonityProfiles]ProxyUpdated:", update_proxy)
      },
      onError: err => console.error(err)
    });




    const [deleteProxy, deleteProxyResult] = useMutation(DELETE_PROXY, {
      onCompleted: data => {
        const { delete_proxy } = data;
        const { num_modified, delete_proxy_id } = delete_proxy;

        updateMessage({
          isShown: true,
          variant: "success",
          content: `Proxy has been deleted.`
        });
        let copy = [...sonityProfiles];
        console.log("[useSonityProfiles]ProxyDeleted:", {
          delete_proxy_id,
          copy
        });

        copy = copy.map(sp=>{
            const proxyToDelete = sp.proxies.find(p=>p.id == delete_proxy_id);
            const toDeselect = sp.selected_proxy_id == delete_proxy_id;
            console.log("[useSonityProfiles]proxyToDelete", proxyToDelete)
            if(proxyToDelete) return {
              ...sp,
              selected_proxy_id: toDeselect ? null : sp.selected_proxy_id,
              selected_proxy: toDeselect ? null : sp.selected_proxy,
              proxies: [...sp.proxies.filter(p=>p.id!= delete_proxy_id)]
            }
            return sp;
        });
        setSonityProfiles(copy);
      },
      onError: err => console.error(err)
    });
    const [commitSelectedProxy, commitSelectedProxyResult] = useMutation(
      SET_SELECTED_PROXY,
      {
        onCompleted: data => {
          const { set_selected_proxy : updatedSp } = data;
          const sp = sonityProfiles.find(sp=>sp.id === updatedSp.id);
          console.log("[useSonityProfiles]ProxySet:", updatedSp)
          if(!updatedSp.selected_proxy) {
            updateMessage({
              isShown: true,
              variant: "success",
              content: `Proxy has been removed for ${sp?.email} -- You will need to restart the driver!`
            });
          } else {
            const { host, name } = updatedSp.selected_proxy;
            updateMessage({
              isShown: true,
              variant: "success",
              content: `Proxy ${name} (${host}) has set for ${sp?.email} -- You will need to restart the driver!`
            });
          }
          
          let copy = [...sonityProfiles];
          copy = copy.map(sp=>{
              if(sp.id === updatedSp.id) {
                console.log("[useSonityProfiles]setNone", {
                  ...sp,
                  selected_proxy_id: updatedSp.selected_proxy_id,
                  selected_proxy:  updatedSp.selected_proxy ? { ...updatedSp.selected_proxy } : null
                })
                return {
                  ...sp,
                  selected_proxy_id: updatedSp.selected_proxy_id,
                  selected_proxy:  updatedSp.selected_proxy ? { ...updatedSp.selected_proxy } : null
                }
              }
              return sp;
          });
          setSonityProfiles(copy);
        },
        onError: err => console.error(err)
      }
    );


    useEffect(() => {
        /* Realtime updates */
        if (eventSubs && eventSubs.type && eventSubs.type === "SONITY_PROFILE_UPDATED") {
    
          const update = { ...eventSubs.payload };
          if (typeof update.updated_at === 'string') update.updated_at = new Date(update.updated_at);
          if (typeof update.created_at === 'string') update.created_at = new Date(update.created_at);
          
          if(update.id == selectedSonityProfile.id) setSelectedSonityProfile({ ...selectedSonityProfile, ...update });
          setSonityProfiles([...sonityProfiles.map(sp => {
            if (sp.id == update.id) {
              return { ...sp, ...update }
            }
            return sp;
          })]);
        }
      }, [eventSubs]);
  return [
    {
      updatedSonityProfile,
      sonityProfilesResult,
      //proxies
      createProxyResult,
      updateProxyResult,
      deleteProxyResult,
      commitSelectedProxyResult
    },
    {
      //methods
      getSonityProfiles,
      updateSonityProfile,
      createSonityProfile,
      removeSonityProfile,
      //proxies
      createProxy,
      updateProxy,
      deleteProxy,
      commitSelectedProxy
    }
  ];
};

export default useSonityProfiles;
